import { createSlice } from '@reduxjs/toolkit';
import { getPlayerPositionZones } from './actions';

export interface LineupPlayerState {
  positionZones: IPlayerPositionZone[];
}

const initialState: LineupPlayerState = {
  positionZones: []
};

const lineupPlayerSlice = createSlice({
  name: 'lineupPlayer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getPlayerPositionZones.fulfilled, (state, action) => {
      state.positionZones = action.payload.data;
    });
  }
});

export default lineupPlayerSlice.reducer;
