import { combineReducers } from '@reduxjs/toolkit';
import authReducer, { AuthState } from './features/auth/slice';
import profileReducer, { ProfileState } from './features/profile/slice';
import settingReducer, { SettingState } from './features/setting/slice';
import { LineupPlayerState } from './features/lineup-player/slice';
import lineupPlayerReducer from './features/lineup-player/slice';

export interface IStoreState {
  auth: AuthState;
  profile: ProfileState;
  setting: SettingState;
  lineupPlayer: LineupPlayerState;
}

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  setting: settingReducer,
  lineupPlayer: lineupPlayerReducer
});

export const rootWhitelistPersist = ['auth', 'profile', 'setting', 'lineupPlayer'];
export const authWhitelistPersist = ['auth'];

export default rootReducer;
