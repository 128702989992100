import { createAsyncThunk } from '@reduxjs/toolkit';
import { generatePath } from 'react-router-dom';
import { apiUrls } from 'routes/config';
import { callDelete, callGet, callOptions, callPatch, callPost, getAxiosError } from 'services/axios';

export const fetchData = createAsyncThunk('lineupPlayers/fetch', async (payload: any, { rejectWithValue, fulfillWithValue }: any) => {
  try {
    const response = await callGet(apiUrls.v1.lineupPlayers.list, payload);

    return fulfillWithValue(response.data);
  } catch (error: any) {
    return rejectWithValue(getAxiosError(error));
  }
});

export const fetchTeamData = createAsyncThunk(
  'lineupPlayers/fetchTeam',
  async (payload: any, { rejectWithValue, fulfillWithValue }: any) => {
    try {
      const { match_id, team_id, ...rest } = payload;
      const response = await callGet(generatePath(apiUrls.v1.lineupPlayers.team, { match_id, team_id }) + '/', rest);

      return fulfillWithValue(response.data);
    } catch (error: any) {
      return rejectWithValue(getAxiosError(error));
    }
  }
);

export const fetchById = createAsyncThunk('lineupPlayers/get', async (id: any, { rejectWithValue, fulfillWithValue }: any) => {
  try {
    const response = await callGet(generatePath(apiUrls.v1.lineupPlayers.view, { id: id }) + '/');

    return fulfillWithValue(response.data);
  } catch (error: any) {
    return rejectWithValue(getAxiosError(error));
  }
});

export const create = createAsyncThunk('lineupPlayers/create', async (payload: any, { rejectWithValue, fulfillWithValue }: any) => {
  try {
    const response = await callPost(apiUrls.v1.lineupPlayers.create, payload, { 'Content-Type': 'multipart/form-data' });

    return fulfillWithValue(response.data);
  } catch (error: any) {
    return rejectWithValue(getAxiosError(error));
  }
});

export const update = createAsyncThunk('lineupPlayers/update', async (payload: any, { rejectWithValue, fulfillWithValue }: any) => {
  try {
    const response = await callPatch(generatePath(apiUrls.v1.lineupPlayers.view, { id: payload.id }) + '/', payload, {
      'Content-Type': 'multipart/form-data'
    });

    return fulfillWithValue(response.data);
  } catch (error: any) {
    return rejectWithValue(getAxiosError(error));
  }
});

export const destroy = createAsyncThunk('lineupPlayers/delete', async (id: any, { rejectWithValue, fulfillWithValue }: any) => {
  try {
    const response = await callDelete(generatePath(apiUrls.v1.lineupPlayers.view, { id: id }) + '/');

    return fulfillWithValue(response.data);
  } catch (error: any) {
    return rejectWithValue(getAxiosError(error));
  }
});

export const fetchOptions = createAsyncThunk(
  'lineupPlayers/fetchOptions',
  async (payload: any, { rejectWithValue, fulfillWithValue }: any) => {
    try {
      const response = await callOptions(apiUrls.v1.lineupPlayers.list, payload);

      return fulfillWithValue(response.data);
    } catch (error: any) {
      return rejectWithValue(getAxiosError(error));
    }
  }
);

export const getPlayerPositionZones = createAsyncThunk(
  'player/positions/zones',
  async (id: any, { rejectWithValue, fulfillWithValue }: any) => {
    try {
      const response = await callGet(apiUrls.v1.players.potisions.zones);

      return fulfillWithValue(response.data);
    } catch (error: any) {
      return rejectWithValue(getAxiosError(error));
    }
  }
);
