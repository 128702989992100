import { ClickAwayListener, Tooltip } from '@mui/material';
import { IconInfoCircleFilled } from '@tabler/icons-react';
import moment from 'moment';
import React, { useState } from 'react';

interface Props {
  data: any[];
  field?: string;
  showInfo?: boolean;
}

const InfoTooltip = ({ data }: { data: any }) => {
  const [open, setOpen] = useState(false);
  const onTooltipClose = () => {
    setOpen(false);
  };

  const onTooltipOpen = () => {
    setOpen(true);
  };

  const title = (
    <div className="grid gap-1 text-xs">
      <p>ภาษา: {data.language_code?.toUpperCase()}</p>
      <p>สถานะ: {data.is_verify ? 'ตรวจสอบแล้ว' : 'ยังไม่ตรวจสอบ'}</p>
      <p>แปลภาษาเวลา: {moment(data.translated_datetime).format('YYYY-MM-DD HH:mm:ss')}</p>
      {data.verified_datetime && <p>ตรวจสอบเวลา: {moment(data.verified_datetime).format('YYYY-MM-DD HH:mm:ss')}</p>}
    </div>
  );

  return (
    <ClickAwayListener onClickAway={onTooltipClose}>
      <div className="inline ml-1">
        <Tooltip
          PopperProps={{
            disablePortal: true
          }}
          onClose={onTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={title}
        >
          <IconInfoCircleFilled className="align-middle" size={20} onClick={onTooltipOpen} cursor="pointer" />
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

const ListTranslationLabel: React.FC<Props> = ({ data, field = 'name', showInfo = false }) => {
  return (
    <div className="grid gap-1">
      {data?.map((item, key) => (
        <span key={`list_${key}`}>
          <b className="text-xs mr-1">[{item.language_code?.toUpperCase()}]</b>
          {item[field] ?? item.name}
          {showInfo && <InfoTooltip data={item} />}
        </span>
      ))}
    </div>
  );
};

export default ListTranslationLabel;
