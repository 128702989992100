import bookmakers from './bookmaker';
import dashboard from './dashboard';
import football from './football';
import general from './general';
import matches from './matches';
import proxies from './proxies';
import settings from './settings';
import tasks from './tasks';
import users from './users';

interface MenuItemChildren {
  id: string;
  title: string;
  type: string;
  url?: string;
  icon?: any;
  breadcrumbs?: boolean;
  target?: boolean;
  children?: MenuItemChildren[];
}
export interface MenuItem {
  id: string;
  title: string;
  type: string;
  allowPermissions?: string[];
  children: MenuItemChildren[];
}

interface MenuItems {
  items: MenuItem[];
}

const menuItems: MenuItems = {
  items: [dashboard, general, football, matches, bookmakers, settings, proxies, tasks, users]
};

export default menuItems;
