import { lazy } from 'react';
import { Loadable } from 'components';
import { routeLinks } from './config';
import MainLayout from 'layouts/MainLayout';
import { RouteObject } from 'react-router-dom';
import AdminGuard from './AdminGuard';

// import view lazy load
const ProxyListView = Loadable(lazy(() => import('views/whoscored/proxies/list')));
const ProxyCreateView = Loadable(lazy(() => import('views/whoscored/proxies/create')));
const ProxyEditView = Loadable(lazy(() => import('views/whoscored/proxies/edit')));
const ProxyDetailView = Loadable(lazy(() => import('views/whoscored/proxies/detail')));

const WhoscoredRoutes: RouteObject = {
  element: (
    <AdminGuard>
      <MainLayout />
    </AdminGuard>
  ),
  children: [
    {
      path: routeLinks.whoscored.workers.proxies.list,
      element: <ProxyListView />
    },
    {
      path: routeLinks.whoscored.workers.proxies.create,
      element: <ProxyCreateView />
    },
    {
      path: routeLinks.whoscored.workers.proxies.edit,
      element: <ProxyEditView />
    },
    {
      path: routeLinks.whoscored.workers.proxies.view,
      element: <ProxyDetailView />
    }
  ]
};

export default WhoscoredRoutes;
