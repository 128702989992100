import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import authService from 'services/authentication';
import { routeLinks } from './config';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/store';
import { IStoreState } from 'store/reducers';
import { fetchProfile } from 'store/features/profile/actions';
import { fetchUserPermissions } from 'store/features/auth/actions';

const AdminGuard = ({ children }: any) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { token } = useSelector((state: IStoreState) => state.auth);
  const location = useLocation();

  useEffect(() => {
    if (token && authService.isValidToken(token)) {
      setLoading(false);

      dispatch(fetchProfile({}));
      dispatch(fetchUserPermissions({}));
    } else {
      navigate(routeLinks.auth.login + `?from=${location.pathname}`, { replace: true });
    }

    // eslint-disable-next-line
  }, [dispatch]);

  return loading ? null : children;
};
export default AdminGuard;
