import axios from './axios';

interface AxiosInterceptorsPropx {
  onLogout?: (payload?: any) => void;
}

class Authentication {
  setAxiosInterceptors = (propx: AxiosInterceptorsPropx) => {
    const { onLogout } = propx;
    const time = Date.now();

    // Default Axios
    axios.defaults.baseURL = process.env.REACT_APP_API_HOST;
    // axios.defaults.timeout = 30000;
    axios.defaults.headers.common.Accept = '*/*';
    axios.defaults.headers.common['Content-Type'] = 'application/json';

    // On request
    axios.interceptors.request.use((request) => request);

    // On response
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        console.error(`RES:${time}`, { status: error?.response?.status || 503, error: `${error}` });
        if (error.response && error.response.status === 401) {
          if (onLogout) {
            onLogout();
          }
        }
        return Promise.reject(error);
      }
    );
  };

  setAuthorization = (token: string) => {
    axios.defaults.headers.common.Authorization = `Token ${token}`;
  };

  delAuthorization = () => {
    delete axios.defaults.headers.common.Authorization;
  };

  isValidToken = (token?: string | null) => {
    return token;
  };
}

const authService = new Authentication();

export default authService;
