import { IconNetwork } from '@tabler/icons-react';
import { routeLinks } from 'routes/config';

const proxies = {
  id: 'proxies',
  title: 'Proxies',
  type: 'group',
  children: [
    {
      id: 'whoscored-list',
      title: 'Whoscored',
      type: 'item',
      url: routeLinks.whoscored.workers.proxies.list,
      activeUrls: [
        routeLinks.whoscored.workers.proxies.list,
        routeLinks.whoscored.workers.proxies.view,
        routeLinks.whoscored.workers.proxies.edit
      ],
      icon: IconNetwork,
      breadcrumbs: true
    }
  ]
};

export default proxies;
