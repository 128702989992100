import { Breakpoint, Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface ConfirmDialogProps {
  open: boolean;
  title?: string;
  children?: any;
  buttonCancelProps?: ButtonProps;
  buttonConfirmProps?: ButtonProps;
  actions?: boolean;
  maxWidth?: Breakpoint | false;
}

const ConfirmDialog = ({
  open,
  title,
  children,
  buttonCancelProps,
  buttonConfirmProps,
  actions = true,
  maxWidth = 'xs'
}: ConfirmDialogProps) => {
  return (
    <Dialog sx={{ '& .MuiDialog-paper': { width: '80%' } }} open={open} maxWidth={maxWidth}>
      {title && <DialogTitle variant="h3">{title}</DialogTitle>}
      {children && <DialogContent>{children}</DialogContent>}
      {actions && (
        <DialogActions>
          <Button {...buttonCancelProps}>{buttonCancelProps?.title || 'Cancel'}</Button>
          <Button {...buttonConfirmProps}>{buttonConfirmProps?.title || 'Confirm'}</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ConfirmDialog;
