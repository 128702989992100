import { IconArticle, IconDeviceDesktop } from '@tabler/icons-react';
import { routeLinks } from 'routes/config';

const settings = {
  id: 'settings',
  title: 'ตั้งค่า',
  type: 'group',
  children: [
    {
      id: 'settings-list',
      title: 'การแสดงผล',
      type: 'item',
      url: routeLinks.settings.sites.list,
      activeUrls: [
        routeLinks.settings.sites.list,
        routeLinks.settings.sites.create,
        routeLinks.settings.sites.edit,
        routeLinks.settings.sites.view
      ],
      icon: IconDeviceDesktop,
      breadcrumbs: true
    },
    {
      id: 'settings-return-info',
      title: 'คำอธิบาย ROI',
      type: 'item',
      url: routeLinks.settings.returns.info.detail,
      activeUrls: [routeLinks.settings.returns.info.detail, routeLinks.settings.returns.info.edit],
      icon: IconArticle,
      breadcrumbs: true
    }
  ]
};

export default settings;
